/*tablet styles*/
@media(min-width: 1200px) { 
    #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler {
    display: none;
 }
 #main-navigation-wrapper .nav > .dropdown > a {
    display: block;
    text-transform: uppercase;
    font-weight:bold;
    font-family: 'Microsoft JhengHei', sans-serif;
    padding: 30px 25px;
    color: #222;
    font-size: 18px;
    font-weight: bold;
}

 #main-navigation-wrapper .nav > .dropdown > .search{
     padding-top: 30px;
     padding-left: 20px;
     
 }
}
@media (min-width: 992px) and (max-width: 1199px){
        #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler {
    display: none;
 }
 #main-navigation-wrapper .nav > .dropdown > a {
    display: block;
    text-transform: uppercase;
    font-weight:bold;
    font-family: 'Microsoft JhengHei', sans-serif;
    padding: 40px 15px;
    color: #222;
    font-size: .9em;
    font-weight: bold;
}
 #main-navigation-wrapper .nav > .dropdown > .search{
     padding-top: 38px;
     padding-left: 20px;
     
 }
    .our-futured-services .box  {
    margin-top: 60px;
}
    .our-futured-services .box  .icon-box{
    margin-left: 30%;
}
.cd-slider-nav p{
   font-size: 18px; 
}
.cd-slider-nav li .slide-number {
    
    margin-left: 40%;
}



.welcome .video_gallery_container {
    margin-top: 30px;
}

.our-numbers .icon-box {
    margin-left: 35%;
    margin-top: 25px;
}

}

@media(min-width: 769px) and (max-width: 991px) {
     #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler {
    display: none;
 }
 #main-navigation-wrapper .nav > .dropdown > a {
    display: block;
    text-transform: uppercase;
    font-weight:bold;
    font-family: 'Microsoft JhengHei', sans-serif;
  
    color: #222;
    font-size: .9em;
    font-weight: bold;
}
 #main-navigation-wrapper .nav > .dropdown > .search{
     padding-top: 11px;
     padding-left: 20px;
     
 }
.navbar-header {
    margin-left: 0;
    margin-right: 0;
    display: block;
    margin-bottom:30px;
}
.top-line .social-icons{
    display: block;
    clear: both;
    text-align: center;
} 
.cd-slider-nav li .slide-number {
    
    margin-left: 33%;
}
.cd-slider-nav span{
   display: none;
} 
.our-futured-services .box  .icon-box{
    margin-left: 43%;
}
.our-futured-services .box  {
    
    margin-bottom: 70px;
}
.contact-us:before {
    display: none;
}
.contact-us p{
    color: #FFFFFF;
   
}
.contact-us form {
    margin-top: 20px;
}
.contact-us h2:before {
    
    top: 50px;
   
}
}
@media(max-width: 768px) {
  #main-navigation-wrapper .logo {
    margin-top: 15px;
    max-width: 250px;
  }

  #main-navigation-wrapper .navbar-brand {
    margin-bottom: 40px;
    display: inline-block;
    text-align: center;
    margin-left:0;
    float: none;
}
.top-line .info {
    
    display: block;
    clear: both;
    text-align: center;
}
.top-line .social-icons{
    display: block;
    clear: both;
    text-align: center;
}   
.cd-slider-nav li .slide-number {
    
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin-left: 23%;
}
.cd-slider-nav ul {
    overflow: hidden;
}
.cd-slider-nav a  {
    padding-top: 10px;
    
}
.cd-slider-nav a  p{
    display: none;
    
}
    .our-futured-services .box  {
    margin-top: 60px;
}
    .our-futured-services .box  .icon-box{
    margin-left: 40%;
}
.welcome .video_gallery_container {
    margin-top: 30px;
}
 .our-numbers .icon-box {
    margin-left: 35%;
    margin-top: 25px;
    
}

    .navbar-nav {
        margin: 0;
    }
   #main-navigation-wrapper.navbar-default {
  background: transparent;
  border: none;
  min-height: auto;
  background: #FFF;
  border-top: 1px solid #38374C;
  border-radius: 0;
  margin: 0;
  padding: 20px 0;
}
    .navbar-default .navbar-toggle:hover {
        border-color: #C4D114;
    }
    .navbar-default .navbar-toggle .icon-bar {
        background: #000;
    }
    .navbar-default .navbar-toggle:hover .icon-bar {
        background: #C4D114;
    }
    .navbar-collapse.collapse {
        display: none !important;
        height: 0 !important;
        padding-bottom: 0;
        overflow: auto !important;
        width: 100%;
    }
    .navbar-default .navbar-collapse {
        background: #497ceb;
        padding: 0px 0px !important;
        padding-bottom: 0px !important;
        border: 1px dashed rgba(255, 255, 255, 0.4);
        margin: 0;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        height: auto !important;
        max-height: 70vh;
        margin-top: 0px;
    }
    .navbar-collapse.in {
        overflow-y: auto;
    }
    .header #main-navigation .nav {
        width: 100%;
        text-align: left;
    }

    #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler {
        background-color: transparent;
        background-image: none;
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 9px 9px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 8px;
        margin-right: 10px;
        z-index: 999;
    }
    #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler .icon-bar {
        background-color: #fff;
        border-radius: 1px;
        display: block;
        height: 1px;
        width: 20px;
    }
    #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler .icon-bar + .icon-bar {
        margin-top: 4px;
    }

    #main-navigation-wrapper .nav {
        width: 100%;
    }
    #main-navigation-wrapper .navbar-form {
        width: 100%;
        display: block;
        border: none;
    }
    #main-navigation-wrapper .nav > li {
        display: block;
        padding: 0;
        width: 100%;
    }
    #main-navigation-wrapper .nav > li > a {
        display: block;
        padding: 15px 15px;
        width: 100%;
        position: relative;
        color: #FFF;
    }
    #main-navigation-wrapper .nav > li + li > a {
        padding: 15px 15px;
        border-top: 1px dashed rgba(255, 255, 255, 0.4);
        position: relative;
    }
    #main-navigation-wrapper .navbar-nav > li > .dropdown-submenu {
       
        
        position: relative;
        width: 100%;
        opacity: 1;
        visibility: visible;
        left:auto;right:auto;
        transform: translate3d(0px, 0px, 0px);
        transition: none !important;
        display: none;
        top:auto;
        float: none;
    }
    #main-navigation-wrapper ul.dropdown-submenu.expand {
    
  display: block;
}


 .searchBtn {
 	display: none;
 }
  #main-navigation-wrapper .nav > .dropdown > .search{
     padding-top: 10px;
     padding-bottom: 10px;
     padding-left: 20px;
     color: #FFFFFF;
 }
 .contact-us:before {
    display: none;
}
.contact-us p{
    color: #FFFFFF;
   
}
.contact-us form {
    margin-top: 20px;
}
.contact-us h2:before {
    
    top: 50px;
   
}
.menuToggle {
    display: block;
    background: rgb(76, 67, 65);
    width: 8em;
    margin-bottom: .2em;
    position: relative;
}
#main-navigation-wrapper ul.dropdown-submenu.expand {
    max-height: 20em;
}
 .cd-hero-slider h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 37px;
  }
   .cd-hero-slider p {
    font-size: 16px;
    font-weight: bold;
  }
   .cd-hero-slider .text-center h2 {
   color: #FFFFFF;
  }
    .cd-hero-slider h2 span{
    color: #48c7ec;
  }
   .cd-hero-slider .btn {
    font-size: 14px;
  }
  .project-content .content-hover {
    opacity: 1 !important;
  }
}

@media (max-width: 640px) {
    
    .our-futured-services .box  {
    margin-top: 60px;
}
    .our-futured-services .box  .icon-box{
    margin-left: 40%;
}
.welcome .video_gallery_container {
    margin-top: 30px;
}

.welcome .video_gallery_container {
   width: 480px;
    height: 310px;
}
.our-numbers .icon-box {
    margin-left: 31%;
    margin-top: 25px;
}
}

@media (max-width: 480px) {

  .menu-margin-xs {
    margin-bottom: 60px;
    margin-top: -50px;
  }
    
    .our-futured-services .box  {
    margin-top: 60px;
}
    .our-futured-services .box  .icon-box{
    margin-left: 35%;
}
.welcome .video_gallery_container {
    margin-top: 30px;
    width: 310px;
    height: 200px;
    overflow: hidden;
}

.our-numbers .icon-box {
    margin-left: 24%;
    margin-top: 25px;
}
}












